html {
  min-height: 100%;
  box-sizing: border-box;


}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  //min-height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #000;
  background-image:
    linear-gradient(to right, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%),
    url("../img/background@2x.jpg");

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;

  font-family: "Geometria Regular", Roboto, sans-serif;

  @media (max-width: $break-medium) {
    background-position: 75% 0;
  }

  @media (max-width: $break-layout) {
    background-image:
            linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 66%, rgba(0,0,0,0) 100%),
            url("../img/back@2x.jpg");

    background-position: top;
    background-size: contain;
  }


}