@font-face {
  font-family: "DIN";
  src: url("../fonts/DIN_Condensed_Bold.otf") format("otf"),
       url("../fonts/DIN_Condensed_Bold.ttf") format("ttf"),
       url("../fonts/DIN_Condensed_Bold.woff2") format("woff2"),
       url("../fonts/DIN_Condensed_Bold.woff") format("woff");
       //url("fonts/DINCondensed-Bold.eot") format("eot");
}

@font-face {
  font-family: "Geometria Regular";
  src: url("../fonts/Geometria.eot");
  src: url("../fonts/Geometria.eot?#iefix") format("embedded-opentype"),
  url("../fonts/Geometria.woff") format("woff"),
  url("../fonts/Geometria.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
