.download-form {
  height: 50px;
  background-color: white;
  border-radius: 30px;
  @include flexbox;

  @media (max-width: $break-layout) {
    flex-direction: column;
    align-items: center;
    height: auto;
    border-radius: 0;
    background-color: transparent;
  }
}

.download-form_input {
  flex: 1;
  position: relative;

  input {
    height: 100%;
    width: 100%;
    border: none;
    padding-left: 60px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    background-color: white;

    &::placeholder {
      color: #aaa;
    }

    &::-ms-clear {
      display: none;
    }

    &:focus {
      border: none;
      outline: none;
    }
  }

  &:before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 14px;
    position: absolute;
    top: 17px;
    left: 30px;
    background-image: url("../img/email-icon.svg");
  }

  @media (max-width: $break-layout) {
    max-width: 337px;
    width: 100%;
    height: 50px;
    margin-bottom: 25px;

    input {
      height: 50px;
      border-radius: 30px;
    }
  }
}

.download-form_btn {
  flex: 0;
  position: relative;
  background-color: $accentColor;
  color: white;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  outline: none;
  border: 0;
  padding-left: 60px;
  padding-right: 28px;
  font-weight: bold;
  cursor: pointer;

  &:disabled {
    background-color: rgb(251, 235, 172);
    cursor: auto;
  }

  &:active {
    background-color: #7f7f7f;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 16px;
    background-size: 19px 16px;
    position: absolute;
    top: 17px;
    left: 28px;
    background-image: url("../img/fill-102.svg");
  }

  @media (max-width: $break-layout) {
    width: 128px;
    height: 40px;
    padding-left: 53px;
    border-radius: 30px;
    line-height: 36px;
    font-size: 12.8px;

    &:before {
      top: 12px;
      left: 25px;
    }
  }
}

.download_error {
  color: #b31010;
  margin: 13px 0;
  margin-left: 60px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.47;
  display: none;

  @media (max-width: $break-layout) {
    margin-left: 0;
    text-align: center;
  }
}

.download_help {
  margin-top: 36px;
  margin-bottom: 46px;
  text-align: center;
  font-size: 12px;
  line-height: 1.5;

  a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: $break-layout) {
    max-width: 280px;
    margin: 25px auto;
    font-size: 16px;

    a {
      font-weight: bold;
    }
  }
}

.download {
  &.-has-error {
    .download-form_input {
      &:after {
        content: '';
        display: inline-block;
        width: 24px;
        height: 20px;
        position: absolute;
        top: 15px;
        right: 20px;
        background-image: url("../img/error.svg");
      }

      input {
        color: #b31010;
      }
    }

    .download_error {
      display: block;
    }

    .download_help {
      margin-top: 13px;
      margin-bottom: 34px;
    }
  }
}


