.site-footer {
  max-width: 620px;
  margin-left: 60px;
  text-align: center;
  padding-bottom: 50px;

  @media (max-width: $break-layout) {
    margin: 0 auto;
    padding: 0 19px;
    padding-bottom: 15px;
  }

  @media (min-width: $break-medium) {
    margin-left: 100px;
  }
}

.site-footer__link {
  position: relative;
  font-weight: normal;
  text-decoration: none;
  color: white;

  @media (max-width: $break-layout) {
    font-weight: 200;
    letter-spacing: 2px;
    color: #888;
  }

  &:after{
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #fff;
    transition: width .4s ease;
    -webkit-transition: width .4s ease;
  }

  &:hover:after{
    width: 100%;
    left: 0;
    background: #fff;
  }
}
.site-footer__logo {
  display: inline-block;
  vertical-align: middle;
}

.social-links {
  list-style: none;
  padding: 0;
  width: 424px;
  margin: 0 auto 35px;
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  flex-wrap: wrap;

  @media (max-width: $break-layout) {
    width: 240px;
    min-height: 100px;
    margin-bottom: 20px;
  }

  li {
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    @media (max-width: $break-layout) {
      flex-basis: 25%;
    }
  }
}
