.main {
  max-width: 620px;
  margin-left: 60px;

  @media (max-width: $break-layout) {
    margin: 0 auto;
    padding: 0 19px;
  }

  @media (min-width: $break-medium) {
    margin-left: 100px;
  }
}

.main-logo {
  font-size: 0;
  text-align: center;
  margin-top: 58px;
  margin-bottom: 28px;

  @media (max-width: $break-layout) {
    margin-top: 200px;
    margin-bottom: 32px;
    img {
      width: 234px;
    }
  }
}

.main-heading {
  font-family: DIN, sans-serif;
  font-size: 50px;
  font-stretch: condensed;
  text-align: center;
  margin: 0;
  margin-bottom: 18px;

  @media (max-width: $break-layout) {
    font-size: 30px;
  }
}


.info-text {
  max-width: 540px;
  margin: 0 auto;
  margin-bottom: 38px;
  line-height: 1.38;

  p {
    margin: 0;
    margin-bottom: 22px;
  }

  @media (max-width: $break-layout) {
    padding: 0 7px;
  }
}

.attention {
  padding-top: 22px;
  padding-bottom: 18px;
  margin-bottom: 40px;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);
  background-color: rgba(255, 255, 255, 0.04);

  strong {
    display: block;
    font-family: DIN, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: condensed;
    text-align: center;
    text-transform: uppercase;
  }

  @media (max-width: $break-layout) {
    padding-top: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
}

.attention-list {
  font-size: 12px;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  margin-top: 17px;

  li {
    padding-left: 37px;
    margin-bottom: 10px;
    position: relative;

    @media (max-width: $break-layout) {
      padding-left: 31px;
      margin-bottom: 25px;
    }

    &:last-child {
      margin: 0;
    }
  }

  li:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width:  8.5px 0 8.5px 13px;
    border-color: transparent transparent transparent $accentColor;
  }

  @media (max-width: $break-layout) {
    padding-left: 16px;
    margin: 17px auto 0;
  }
}
